import { FC } from "react";
import './Icon.scss';
interface IconNormalProps {
  color : string,
}

const IconNormalVehicle:FC<IconNormalProps> = ({color}) => {

  return (
    <svg className="widthHeightIcon" viewBox="0 0 62 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2253_1263)">
        <path d="M31.0716 53.3571C44.7505 53.3571 55.8394 42.2682 55.8394 28.5893C55.8394 14.9104 44.7505 3.82141 31.0716 3.82141C17.3927 3.82141 6.30371 14.9104 6.30371 28.5893C6.30371 42.2682 17.3927 53.3571 31.0716 53.3571Z"
              fill={color}/>
      </g>
      <path d="M40.1549 20.2332C40.0279 19.8625 39.7877 19.5409 39.4682 19.3139C39.1488 19.0868 38.7662 18.9656 38.3743 18.9675H24.5825C24.1908 18.9671 23.8087 19.0888 23.4893 19.3157C23.1699 19.5425 22.9292 19.8632 22.8005 20.2332L20.1934 27.7444V37.7819C20.1944 38.1142 20.3268 38.4325 20.5618 38.6675C20.7968 38.9025 21.1151 39.0349 21.4474 39.0359H22.7014C23.0337 39.0349 23.3521 38.9025 23.587 38.6675C23.822 38.4325 23.9544 38.1142 23.9555 37.7819V36.5279H39.0013V37.7819C39.0023 38.1142 39.1348 38.4325 39.3697 38.6675C39.6047 38.9025 39.923 39.0349 40.2553 39.0359H41.5094C41.8416 39.0349 42.16 38.9025 42.395 38.6675C42.6299 38.4325 42.7624 38.1142 42.7634 37.7819V27.7444L40.1549 20.2332ZM24.5825 32.7605C24.2104 32.7605 23.8468 32.6502 23.5374 32.4435C23.2281 32.2368 22.987 31.9431 22.8446 31.5993C22.7022 31.2556 22.665 30.8774 22.7376 30.5125C22.8102 30.1476 22.9893 29.8125 23.2524 29.5494C23.5154 29.2863 23.8506 29.1072 24.2155 29.0346C24.5804 28.962 24.9586 28.9993 25.3023 29.1416C25.646 29.284 25.9398 29.5251 26.1465 29.8344C26.3532 30.1438 26.4635 30.5075 26.4635 30.8795C26.4639 31.1266 26.4155 31.3714 26.321 31.5997C26.2266 31.8281 26.0881 32.0356 25.9134 32.2104C25.7386 32.3851 25.5311 32.5236 25.3027 32.6181C25.0744 32.7125 24.8296 32.7609 24.5825 32.7605ZM38.3756 32.7605C38.0035 32.7605 37.6399 32.6502 37.3305 32.4435C37.0212 32.2368 36.7801 31.9431 36.6377 31.5993C36.4953 31.2556 36.4581 30.8774 36.5307 30.5125C36.6032 30.1476 36.7824 29.8125 37.0455 29.5494C37.3085 29.2863 37.6437 29.1072 38.0086 29.0346C38.3735 28.962 38.7517 28.9993 39.0954 29.1416C39.4391 29.284 39.7329 29.5251 39.9396 29.8344C40.1463 30.1438 40.2566 30.5075 40.2566 30.8795C40.257 31.1267 40.2085 31.3716 40.114 31.6C40.0195 31.8285 39.8809 32.0361 39.706 32.2108C39.5311 32.3856 39.3234 32.5241 39.0949 32.6184C38.8664 32.7128 38.6215 32.7611 38.3743 32.7605H38.3756ZM22.7014 26.4904L24.5825 20.8485H38.3743L40.2553 26.4904H22.7014Z"
            fill="white"/>
      <defs>
        <filter id="filter0_d_2253_1263" x="0.303711" y="0.821411" width="61.5356" height="61.5357" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="3"/>
          <feGaussianBlur stdDeviation="3"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2253_1263"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2253_1263" result="shape"/>
        </filter>
      </defs>
    </svg>
  );
}

export default IconNormalVehicle;