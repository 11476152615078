import './App.scss';
import {Route, Routes} from "react-router";
import {BrowserRouter} from "react-router-dom";
import PageUnavaible from '../../views/PageUnavaible';
import Home from '../../views/Home';
import {GeneralContext, GeneralContextType, GeneralProvider} from "../../context/GeneralContext";
import {useContext} from 'react';

const App = () => {
  return (
    <GeneralProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/error/:number" element={<PageUnavaible/>}></Route>
          <Route path="/:type/:token" element={<Home/>}></Route>
          <Route path="*" element={<PageUnavaible/>}></Route>
        </Routes>
      </BrowserRouter>
    </GeneralProvider>
  );
}
export const useType = (): GeneralContextType | null => {
  return useContext(GeneralContext);
}

export default App;
