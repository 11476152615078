export const getAddress = (lat: number, lng: number): Promise<string> => {
  const geocoder = new google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode({location: {lat, lng}}, (results, status) => {
      if (status === "OK" && results && results.length > 0) {
        resolve(results[0].formatted_address);
      }
    });
  });
}

export const getArrivalTime = (origin: number, eta: boolean): string => { // return format Thu Mar 23 2023 04:39:23 GMT+0100 (heure normale d’Europe centrale)
  if (origin !== 0) {
    const now = new Date();
    const arrivalTime = new Date( eta ? now.getTime() + origin * 1000 : origin);
    return arrivalTime.toString();
  } else {
    return "error";
  }
};