import moment from "moment";
import 'moment/locale/fr';

export const getHour = (hour: string): string => { //Format Thu Mar 23 2023 04:39:23 GMT+0100 (heure normale d’Europe centrale) to 00h00
  const date = new Date(hour);
  const midnight = moment(date).startOf('day');
  const elapsedTime = moment.duration(moment(date).diff(midnight));
  const formattedTime = `${elapsedTime.hours()}h${elapsedTime.minutes().toString().padStart(2, '0')}`;
  return formattedTime;
}

export const getSecondeToHour = (seconde: number): string => { // seconde to 00h00
  const hours = Math.floor(seconde / 3600);
  const minutes = Math.floor((seconde % 3600) / 60);
  const hoursStr = hours.toString().padStart(2, '0');
  const minutesStr = minutes.toString().padStart(2, '0');
  return `${hoursStr}h${minutesStr}`;
}

export const getDateDayMonth = (date: string): string => { // Format Thu Mar 23 2023 04:39:23 GMT+0100 (heure normale d’Europe centrale) to 1 janvier
  const dateObj = moment(date);
  const day = dateObj.format('D');
  const month = dateObj.format('MMMM');
  return `${day} ${month}`;
}

export const getDateDayMonthYear = (date: string): string => { // Format Thu Mar 23 2023 04:39:23 GMT+0100 (heure normale d’Europe centrale) to 1/01/1999
  return moment(date).format('L');
}

export const compareMyDateAndNow = (date: string): boolean => {
  const myDate = new Date(date);
  const dateNow = new Date();
  if (myDate.toDateString() === dateNow.toDateString()) {
    return true;
  } else {
    return false;
  }
}