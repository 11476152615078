import React, {CSSProperties, FC, MouseEventHandler} from 'react';
import './Button.scss';

interface ButtonProps {
  text: string,
  onClick: MouseEventHandler<HTMLButtonElement>,
  textColor: string,
  backgroundColor: string,
  backgroundColorHover: string,
}

const Button: FC<ButtonProps> = ({text, onClick, textColor, backgroundColor, backgroundColorHover}) => {
  const styles = {
    '--button-text-color': textColor,
    '--button-background-color': backgroundColor,
    '--button-background-color-hover': backgroundColorHover,
  } as CSSProperties;

  return (
    <button className="poppinsMedium radius4 trans02s" onClick={onClick} style={styles}>{text}</button>
  );
}

export default Button;