import {FC, useEffect, useState} from "react";
import Map from '../components/map/Map';
import useConnectWS from '../hooks/useConnectWS';
import Details from '../components/details/Details';
import PanelStatus from '../components/panel-status/PanelStatus';
import {useType} from "../components/app/App";
import DetailsHoursDay from "../components/details-hours-day/DetailsHoursDay";
import Card from "../components/card/Card";
import {GeneralContextType} from "../context/GeneralContext";
import DetailsVehicles from "../components/details-vehicules/DetailsVehicles";
import {useNavigate} from "react-router-dom";
import {MapProvider} from "../context/MapContext";
import {WsData} from "../interface/interface";

const Home: FC = () => {
  const {wsData, listws}: { wsData: any, listws: Map<number, WsData> } = useConnectWS()
  const provider: GeneralContextType | null = useType();
  const [iconStatusBtn, setIconStatusBtn] = useState<string>("chevron_left");
  const [showHideStatusClass, setShowHideStatusClass] = useState<string>("hideStatusList");
  const [status, setStatus] = useState<Array<object>>([{color: "#a81616", name: "Arrêt"}, {color: "#0c7c16", name: "Normal"}, {color:"#424242", name:"Privé"}]);
  const navigate = useNavigate();
  const [intervalTimer, setIntervalTimer] = useState<any | null>(null);
  const [hideSchedule, setHideSchedule] = useState<boolean>(true);
  const [hideVehicles, setHideVehicles] = useState<boolean>(true);
  const [scheduleData, setScheduleData] = useState<object>({});
  const [options, setOptions] = useState<google.maps.MapOptions>(
    {
      center: {lat: 48.8566, lng: 2.3522},
      zoom: 8,
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: false,
      gestureHandling: "greedy",
    }
  );

  const [listVehicle, setListVehicle] = useState<string[]>([]);
  const [points, setPoints] = useState<Map<number, WsData>>(); //() => structuredClone(listws)

  const closeDetails = (schedule: boolean) => {
    schedule ? setHideSchedule(true) : setHideVehicles(true);
  }

  const showHideStatus = () => {
    iconStatusBtn === "chevron_left" ?
      handleStatusList(false) : handleStatusList(true)
  }

  const showDetails = (schedule: boolean) => {
    schedule ? setHideSchedule(false) : setHideVehicles(false);
  }

  const handleStatusList = (left: boolean): void => {
    if (left) {
      setIconStatusBtn("chevron_left");
      setShowHideStatusClass("hideStatusList");
    } else {
      setIconStatusBtn("chevron_right");
      setShowHideStatusClass("showStatusList");
    }
  }


  useEffect(() => {
    /** Timer logout and redirection 401 **/
    provider?.setTimeLeft(wsData?.endDate);
    let time = provider?.timeLeft;

    if (intervalTimer === null) {
      const newIntervalId = setInterval(() => {
        if (time !== undefined && time !== null) {
          time = time - 1;
        }
        if (time === 0) { //ajouter || time === null
          clearInterval(newIntervalId);
          setIntervalTimer(null);
          navigate(`/error/401`);

        }
      }, 1000);
      setIntervalTimer(newIntervalId);

    } else {
      clearInterval(intervalTimer);
      setIntervalTimer(null);
    }
  }, [wsData, navigate, provider]);


  useEffect(() => { /** set vehicule registration **/
    if (wsData?.vehicleIdRegistrationMap !== undefined) {
      const list = Object.values<string>(wsData?.vehicleIdRegistrationMap).map(registration => {
        if (registration === "") {
          return "NC";
        } else {
          return registration;
        }
      }).map((vehicle) => vehicle);

      setListVehicle(list);
    }
  }, [wsData]);


  useEffect(() => { //init value markers
    if (wsData?.lastTrame) {
      if (provider?.type === "ps") {
        setScheduleData(wsData?.schedule); //retrieve hour schedule
      }

      setPoints(listws);
    }
  }, [listws, wsData]);

  return (
    <MapProvider>
      <div className="height100 relative">
        <Map wsData={wsData} options={options} markers={points}/>
        {
          provider?.type === "ps" && !hideSchedule && (<Details schedule={true} title={"Détail des heures de journées"}
                                                                definition={"En dehors de ces horaires vous n’aurez pas accès au partage"}
                                                                content={<DetailsHoursDay scheduleData={scheduleData}/>}
                                                                handleClick={closeDetails}/>)
        }
        {
          provider?.type === "ps" && !hideVehicles && (
            <Details schedule={false} title={"Liste des véhicules partagés"} definition={null}
                     content={<DetailsVehicles vehicles={listVehicle} setClose={setHideVehicles}/>}
                     handleClick={closeDetails}/>)
        }
        {
          provider?.type === "ps" && (
            <PanelStatus status={status} showHide={showHideStatusClass} handleClick={showHideStatus}
                         iconBtn={iconStatusBtn}/>)
        }

        <Card wsData={wsData} vehicles={listVehicle} showDetails={showDetails}/>
      </div>
    </MapProvider>
  );
}

export default Home;