import './PsCard.scss';
import Button from "../../global-components/button/Button";
import Tooltip from "../../global-components/tooltip/Tooltip";
import {useEffect, useState, FC, useContext} from "react";
import {getArrivalTime} from "../../utils/MapsUtils";
import {getDateDayMonthYear} from "../../utils/MomentUtils";
import {MapContext} from "../../context/MapContext";

interface PsCardProps {
  handleClick: (event: any, schedule: boolean) => void,
  vehicles: string[],
  wsData: any
}

const PsCard: FC<PsCardProps> = ({handleClick, vehicles, wsData}) => {
  const mapContext = useContext(MapContext);

  const [nbVehicleDisplay, setNbVehicleDisplay] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>("jj/mm/aaaa");
  const [endDate, setEndDate] = useState<string>("jj/mm/aaaa");

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      let newNbVehicleDisplay = 0;
      if (screenWidth >= 912) {
        newNbVehicleDisplay = 4;
      } else {
        newNbVehicleDisplay = 2;
      }
      setNbVehicleDisplay(newNbVehicleDisplay);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setEndDate(getDateDayMonthYear(getArrivalTime(wsData?.endDate, false)));
    setStartDate(getDateDayMonthYear(wsData?.startDate));
  }, [wsData]);

  const focusVehicle = (item: string) => {
    mapContext?.setRegistration(item);
  }

  let button;

  if (wsData?.schedule) {
    if (Object.entries(wsData?.schedule).length > 0) {
      button = <Button text="Détail des heures" onClick={(e) => handleClick(e, true)} textColor="#ffffff"
      backgroundColor="#2196F3" backgroundColorHover="#2F80ED"/>
    }
  }

  return (
    <div className="psCard height100">
      <div className="flexSpaceBetweenCenter height50">
        <div className="flexHorizontal gap32">
          <div className="poppinsMedium flexVerticalStart gap8">
            <div className="fontSize16">Début du partage</div>
            <div className="fontSize14 primaryColor">{startDate}</div>
          </div>
          <div className="poppinsMedium flexVerticalStart gap8">
            <div className="fontSize16">Fin du partage</div>
            <div className="fontSize14 primaryColor">{endDate}</div>
          </div>
        </div>
        {button}
      </div>
      <div className="line absolute left"/>
      <div className="flexVerticalCenterStart height50 poppinsMedium gap8">
        <div className="flexHorizontal gap16">
          <div className="fontSize16">Immatriculation des véhicules partagés</div>
          <Tooltip text="NC = Non communiqué par l’expéditeur"/>
        </div>
        {vehicles.length > nbVehicleDisplay ?
          <div className="flexHorizontal gap24 textEllipsis width100">
            {vehicles.slice(0, nbVehicleDisplay).map((item, id) => (
              <div className={item.length > 10 ? "textEllipsis fontSize14 primaryColor registration pointer" : "fontSize14 primaryColor registration pointer"}
                   key={id} onClick={() => focusVehicle(item)}>{item}</div>
            ))}
            <Button text={"+ " + (vehicles.length - nbVehicleDisplay) + " autres"} textColor="#2196F3"
                    backgroundColor="#2196F319" backgroundColorHover="#2196F347"
                    onClick={(e) => handleClick(e, false)}/>
          </div>
          :
          <div className="flexHorizontal gap24 textEllipsis width100">
            {vehicles.map((item, id) => (
              <div className="fontSize14 primaryColor pointer registration" key={id} onClick={() => focusVehicle(item)}>{item}</div>
            ))}
          </div>
        }
      </div>
    </div>
  );
}

export default PsCard;