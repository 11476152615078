import {FC} from 'react';
import './Card.scss';
import EtaCard from "../eta-card/EtaCard";
import {GeneralContextType} from "../../context/GeneralContext";
import {useType} from "../app/App";
import PsCard from "../ps-card/PsCard";

interface CardProps {
  showDetails: (schedule: boolean) => void,
  vehicles: string[],
  wsData: any
}


const Card: FC<CardProps> = ({showDetails, vehicles, wsData}) => {
  const provider: GeneralContextType | null = useType();

  const setSchedule = (event: Event, schedule: boolean) => {
    event.stopPropagation();
    showDetails(schedule);
  }

  return (
    <div className={"card absolute radiusCard backWhite bottom " + provider?.type}>
      {provider?.type === "eta" ?
        <EtaCard wsData={wsData}/>
        :
        <PsCard wsData={wsData} handleClick={setSchedule} vehicles={vehicles}/>
      }
    </div>
  );
}

export default Card;