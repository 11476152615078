import React, {createContext, FC, ReactElement, useState} from "react";

interface MapContextType {
  registration: string;
  setRegistration: (registration: string) => void;
}

export let MapContext = createContext<MapContextType | null>(null);

interface MapProviderProps {
  children: ReactElement | ReactElement[],
}

export const MapProvider: FC<MapProviderProps> = ({children}) => {
  const [registration, setRegistration] = useState<string>('');

  const value = {registration, setRegistration};

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}