import {useEffect, useRef, useState} from "react";
import useWebSocket, {ReadyState} from 'react-use-websocket';
import {useNavigate, useParams} from "react-router-dom";
import {GeneralContextType} from "../context/GeneralContext";
import {useType} from "../components/app/App";
import {WsData} from "../interface/interface";

const useConnectWS = () => {
  const [wsData, setWsData] = useState<object | undefined>({});
  const [listws, setListws] = useState<Map<number, WsData>>(new Map());
  const navigate = useNavigate();
  const {token} = useParams();
  const socketUrl = useRef(`${process.env.REACT_APP_WS}${token}`);
  const provider: GeneralContextType | null = useType();

  const {lastJsonMessage, readyState}: { lastJsonMessage: any, readyState: number } = useWebSocket(socketUrl.current, {
    onError: (mess) => {
      if (token) {
        switch (mess.type) {
          case "error":
            navigate(`/error/401`);
            break;
          default:
            break;
        }
      } else {
        navigate(`/error/404`);
      }
    },
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(() => {

    if (lastJsonMessage !== null) {

      if (lastJsonMessage.exceptionType) {
        navigate(`/error/401`);
      } else {
        setWsData(lastJsonMessage);

        let temps : any[] = [];

        lastJsonMessage.lastTrame.sensorValues.forEach((sensor: any)=> {
          switch (sensor.inputName) {
            case "BLE_TEMPERATURE_1":
              sensor.value = sensor.value / 100;
              temps.push(sensor);
              break;
            case "BLE_TEMPERATURE_2":
              sensor.value = sensor.value / 100;
              temps.push(sensor);
              break;
            case "BLE_TEMPERATURE_3":
              sensor.value = sensor.value / 100;
              temps.push(sensor);
              break;
            case "BLE_TEMPERATURE_4":
              sensor.value = sensor.value / 100;
              temps.push(sensor);
              break;
          }
        });

        const formattedData = {
          position: {lat: lastJsonMessage?.lastTrame?.coordY, lng: lastJsonMessage?.lastTrame?.coordX},
          id: lastJsonMessage?.lastTrame?.vehicleId,
          registration: provider?.type === "ps" ? lastJsonMessage?.vehicleIdRegistrationMap[lastJsonMessage?.lastTrame?.vehicleId] : lastJsonMessage?.vehicleId,
          schedule: lastJsonMessage?.schedule,
          idle: lastJsonMessage?.lastTrame?.idle,
          moving: lastJsonMessage?.lastTrame?.moving,
          private: lastJsonMessage?.lastTrame?.private,
          stationnary: lastJsonMessage?.lastTrame?.stationnary,
          endPosition: {lat: lastJsonMessage?.destination?.x, lng: lastJsonMessage?.destination?.y},
          gendate: lastJsonMessage?.lastTrame?.gendate,
          address: lastJsonMessage?.lastTrame?.street +" "+lastJsonMessage?.lastTrame?.zipCode+" "+lastJsonMessage?.lastTrame?.city,
          temperatures: temps,
        }

        setListws((prevDataMap) => {
          const newDataMap = new Map(prevDataMap);
          newDataMap.set(lastJsonMessage?.lastTrame?.vehicleId, formattedData);
          return newDataMap;
        });

      }
    }

  }, [lastJsonMessage]);

  return {wsData, listws};
};

export default useConnectWS;