import {FC, useEffect, useState} from "react";
import {useParams} from "react-router";

const PageUnavaible: FC = () => {
  const {number} = useParams<string | "">();
  const [mess, setMess] = useState<string | "">("");

  useEffect(() => {
    switch (number) {
      case '401':
        setMess("Cette page a été supprimée ou n'est actuellement pas disponible");
        break;
      case '404':
        setMess("Page inconnue");
        break;
      default:
        break;
    }
  }, [number]);

  return (
    <div className="flexHorizontalCenter height100 backPageUnavailable">
      <div className='flexVerticalCenter'>
        <div className='width100 relative'>
          <p className='alignText relative colorWhite mB32 fontSize96 textShadowWhite zIndex2'>
            Page <br/> Indisponible
          </p>
          <p className='width100 top alignText absolute fontSize96 textStrokeWhite zIndex1'>
            Page <br/> Indisponible
          </p>
        </div>
        <p className='alignText fontSize24 mSide10 colorWhite'>
          {mess}
        </p>
      </div>
    </div>
  );
}

export default PageUnavaible;
