import './DetailsHoursDay.scss';
import {FC, useMemo, useState} from "react";

interface DetailsProps {
  scheduleData: object,
}

const DetailsHoursDay: FC<DetailsProps> = ({scheduleData}) => {
  const [schedule, setSchedule] = useState<object>(scheduleData);
  const [days] = useState<Array<string>>(['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']);

  const fillSchedule = () => {
    let arr = Object.entries({...schedule}),
      days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
      sorter = {"mon": 1, "tue": 2, "wed": 3, "thu": 4, "fri": 5, "sat": 6, "sun": 7} as any,
      daysSchedule = [] as Array<string>;

    for (let i = 0; i < arr.length; i++) {
      const elem = arr[i];
      daysSchedule.push(elem[0]);
    }

    let daysToAdd = days.filter((d) => {
      return !daysSchedule.includes(d)
    });

    for (let i = 0; i < daysToAdd.length; i++) {
      const day = daysToAdd[i];
      arr.push([day, null]);
    }

    arr.sort((a, b): any => {
      let day1 = a[0].toString(),
        day2 = b[0].toString();

      return sorter[day1] - sorter[day2];
    })

    let newObjSorted = Object.fromEntries(arr);

    setSchedule(newObjSorted);
  }

  useMemo(fillSchedule, []);

  const listSchedule: any = Object.entries(schedule).map((arr, index) => {
    if (arr[1]) {
      return <div className='flexHorizontalSpaceBetweenStart mB42 width100' key={arr[0]}>
        <div className='width30 poppinsMedium'>{days[index]}</div>
        <div className='width70 flexVerticalCenter'>{
          arr[1].length ?
            arr[1].map((d: any, i: number) => {
              return <div key={i} className={`${i !== arr[1].length - 1 ? "mB32" : ""} primaryColor`}><span
                className='mR16'>{d.start}</span> - <span className='mL16'>{d.end}</span></div>
            })
            :
            <span className='primaryColor'>Toute la journée</span>
        }</div>
      </div>
    } else {
      return <div className='flexHorizontalSpaceBetweenStart mB42 width100' key={arr[0]}>
        <div className='width30 poppinsMedium'>{days[index]}</div>
        <div className='width70 flexVerticalCenter'>Non disponible</div>
      </div>
    }
  });

  return (
    <div className='flexVerticalStart width80 ctn'>
      {listSchedule}
    </div>
  );
}

export default DetailsHoursDay;