import React, {FC, ReactElement, useMemo, useState} from "react";

export let GeneralContext = React.createContext<GeneralContextType | null>(null)

export interface GeneralContextType {
  type: string,
  timeLeft: number,
  setTimeLeft: (timeLeft: number) => void,
}

interface GeneralProviderProps {
  children: ReactElement | ReactElement[],
}

export const GeneralProvider: FC<GeneralProviderProps> = ({children}) => {
  const [type, setType] = useState<string>('');
  const [timeLeft, setTimeLeft] = useState<number>(0);

  const initType = useMemo(() => {

    const url = document.location.origin,
      type = window.location.href.split(url).pop()?.split('/')[1];

    if (type === "ps") { //a verifier en prod
      setType('ps');
    } else if (type === "eta") {
      setType('eta');
    } else { //En local change value setType pour tester et pas oublier de changer la liste dans home
      setType('ps')
    }

    return type;
  }, [type]);

  const value = {type, timeLeft, setTimeLeft};

  return <GeneralContext.Provider value={value}>{children}</GeneralContext.Provider>;
}