import './DetailsVehicles.scss';
import {FC, useContext} from "react";
import {MapContext} from "../../context/MapContext";

interface DetailsProps {
  vehicles: string[],
  setClose: (close: boolean) => void,
}

const DetailsVehicles: FC<DetailsProps> = ({vehicles, setClose}) => {
  const mapContext = useContext(MapContext);

  const focusVehicle = (item: string) => {
    mapContext?.setRegistration(item);
    setClose(true);
  }

  return (
    <div className='detailsVehicle flexVerticalStart ctn fontSize16 p8'>
      <div className='flexHorizontalStart grid width100'>
        {
          vehicles.map((item, index) => (
            <span className={item.length > 10 ? 'breakWord hoverRegistration pointer' : 'hoverRegistration pointer'} key={index} onClick={() => focusVehicle(item)}>{item}</span>
          ))
        }
      </div>
    </div>
  );
}

export default DetailsVehicles;