import './Details.scss';
import {FC, ReactNode} from "react";

interface DetailsProps {
  content: ReactNode
  handleClick: (schedule: boolean) => void,
  title: string,
  definition: string | null,
  schedule: boolean
}

const Details: FC<DetailsProps> = ({handleClick, content, title, definition, schedule}) => {

  const isSchedule: boolean = schedule;

  return (
    <div className='width100 height100 zIndex absolute top flexHorizontalCenter backBlackTransparent'>
      <div className='width100 height100 pointer absolute top zIndex1' onClick={() => handleClick(isSchedule)}/>
      <div className='radius8 backWhite detailsCtn relative zIndex2 p32 borderBox'>
        <div className='flexSpaceBetweenCenter mB16'>
          <span className='poppinsMedium fontSize24 title'>{title}</span>
          <button className='btn radius4 backRed colorWhite pclose trans02s hover' onClick={() => handleClick(isSchedule)}>
            <span className="material-symbols-outlined">close</span>
          </button>
        </div>
        {definition && (<div className='fontSize14'>{definition}</div>)}
        <div className='mT32 width100 height80 overflowY scrollbar scrollbarRadius'>
          {content}
        </div>
      </div>
    </div>
  );
}

export default Details;
