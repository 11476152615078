import './EtaCard.scss';
import {useEffect, useState, FC} from "react";
import {getAddress, getArrivalTime} from "../../utils/MapsUtils";
import {compareMyDateAndNow, getDateDayMonth, getHour, getSecondeToHour} from "../../utils/MomentUtils";
import IconNormalVehicle from "../../res/icon-personalized/IconNormalVehicle";

interface EtaCardProps {
  wsData: any
}

const EtaCard: FC<EtaCardProps> = ({wsData}) => {
  const [address, setAddress] = useState<string>('NC');
  const [expectedArrival, setExpectedArrival] = useState<string | null>('NC');
  const [remainingTime, setRemainingTime] = useState<string | null>('00h00');
  const errMess = "Erreur de calcul, veuillez patientez...";

  const getLocalisation = () => {
    getAddress(wsData?.lastTrame?.coordY, wsData?.lastTrame?.coordX).then((address) => {
      setAddress(address);
    }).catch((error) => {
      setAddress("Impossible de récupérer l'adresse");
    });
  }

  const getExpectedArrival = () => {
    
    const arrival = getHour(getArrivalTime(wsData?.result?.secondsDuration, true));
    
    if (!compareMyDateAndNow(getArrivalTime(wsData?.result?.secondsDuration, true))) {
      setExpectedArrival(arrival !== "NaNhNaN" ? arrival : null);
    } else {
      const date = getDateDayMonth(getArrivalTime(wsData?.result?.secondsDuration, true));

      setExpectedArrival(date !== "Invalid date Invalid date" && arrival !== "NaNhNaN" ? date + " à " + arrival : null);
    }
  }

  const getRemainingTime = () => {
    if (wsData?.result?.secondsDuration > 0) {
      const hour = getSecondeToHour(wsData?.result?.secondsDuration);
      setRemainingTime(hour);
    } else {
      setRemainingTime(null);
    }
  }

  useEffect(() => {
    if (wsData?.lastTrame !== null) {
      if (wsData?.lastTrame?.coordY && wsData?.lastTrame?.coordX) {
        getLocalisation();
        getRemainingTime();
        getExpectedArrival();
      }
    }
  }, [wsData]);

  return (
    <div className="etaCard height100">
      <div className="flexHorizontal height50">
        <IconNormalVehicle color="#2C7D3D"/>
        <div className="flexVerticalStart gap8">
          <div className="fontSize18 poppinsMedium">Véhicule partagé</div>
          <div className="fontSize16 tertiaryColor">{address}</div>
        </div>
      </div>
      <div className="line absolute left"/>
        {
          !remainingTime && !remainingTime && (
            <div className="flexHorizontal height50 gap8">
              <div className='poppinsMedium gap8 mR32 flexVerticalStart'>{errMess}</div>
            </div>
          ) 
        }
        {
          remainingTime && expectedArrival && (
            <div className="flexHorizontal height50 gap8">      
              <div className="poppinsMedium gap8 mR32 flexVerticalStart">
                <div className="fontSize20">{remainingTime}</div>
                <div className="fontSize18 tertiaryColor textUppercase">Temps restant</div>
              </div>
              <div className="poppinsMedium gap8 flexVerticalStart">
                <div className="fontSize20">{expectedArrival}</div>
                <div className="fontSize18 primaryColor textUppercase">Arrivée prévue</div>
              </div>
            </div>
          )
        }
    </div>
  );
}

export default EtaCard;