import './Map.scss';
import {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {useType} from "../app/App";
import {GeneralContextType} from "../../context/GeneralContext";
import ReactDOMServer from 'react-dom/server';
import normalIcon from '../../res/svg/normal.svg';
import stopIcon from '../../res/svg/stop.svg';
import privateIcon from '../../res/svg/private.svg';
import stationnaryIcon from '../../res/svg/stationnary.svg';
import {getDateDayMonthYear, getHour} from "../../utils/MomentUtils";
import {MapContext} from "../../context/MapContext";
import {TSMap} from "typescript-map"
import {getAddress} from "../../utils/MapsUtils";
import {WsData} from "../../interface/interface";


interface MapProps {
  options: google.maps.MapOptions;
  markers: Map<number, WsData> | undefined;
  wsData: any
}

const Map: FC<MapProps> = ({options, markers, wsData}) => {
  const mapContext = useContext(MapContext);

  const mapRef = useRef<HTMLDivElement>(null);
  const [markerRefs, setMarkerRefs] = useState<TSMap<number, google.maps.Marker>>(new TSMap()); //new Map<number, google.maps.Marker>()
  const [infoWindowRefs, setInfoWindowRefs] = useState<TSMap<number, google.maps.InfoWindow>>(new TSMap()); //new Map<number, google.maps.Marker>()
  const directionsService = useRef<google.maps.DirectionsService>();
  const directionsRenderer = useRef<google.maps.DirectionsRenderer | null>();
  const [startMarker, setStartMarker] = useState<google.maps.Marker | null>(null);
  const [endMarker, setEndMarker] = useState<google.maps.Marker | null>(null);
  const type: GeneralContextType | null = useType();
  const [oldMarkers, setOldMarkers] = useState<Map<number, WsData>>();

  const map = useMemo(() => {
    if (typeof window.google === 'undefined' || typeof window.google.maps === 'undefined') {
      console.warn('API Google Maps n\'est pas disponible');
      return null;
    }
    if (!mapRef.current) return null;

    const map = new window.google.maps.Map(mapRef.current, options);
    return map;

  }, [mapRef.current, options]);

  const marketicon = (marker: any): string => {
    if (marker?.private) {
      return privateIcon;
    } else if (marker?.idle) {
      return stopIcon;
    } else {
      return normalIcon;
    }
  };

  const popupContentPS = (idVehicle: number) => {  //creation display of ps content
    let marker = markers?.get(idVehicle);
    let markerGoogle = markerRefs.get(idVehicle);
    let allContent, date: any;
    let temps : string = "";
    
    if (marker && marker.gendate && markerGoogle) {
      date = `${getDateDayMonthYear(new Date(marker.gendate * 1000) as any)} - ${getHour(new Date(marker.gendate * 1000) as any)}`;
      
      marker.temperatures?.forEach((temp, index)=> {
        temps += index === marker?.temperatures?.length as number - 1 ? temp.value + " °C" : temp.value + " °C, ";
      });

      allContent = (
        <div className="flexHorizontal gap8 poppins">
          <svg width="15" height="23" viewBox="0 0 25 25">
            <path fill="#2196F3FF"
                  d="M12 0c-6.63 0-12 5.37-12 12 0 8.45 11.38 19.29 11.84 19.77 0.21 0.2 0.49 0.31 0.77 0.31s0.56-0.11 0.77-0.31c0.46-0.48 11.84-11.32 11.84-19.77 0-6.63-5.37-12-12-12zM12 18.75c-2.97 0-5.39-2.42-5.39-5.39s2.42-5.39 5.39-5.39 5.39 2.42 5.39 5.39-2.42 5.39-5.39 5.39z"></path>
          </svg>
          <div>
            <h2 className="fontSize12 secondaryColor">{marker?.registration}</h2>
            <p className="fontSize10 tertiaryColor">{marker.address}</p>
            <p className="fontSize10 tertiaryColor">{date}</p>
            <p className='fontSize10 tertiaryColor'>{temps}</p>
          </div>
        </div>
      );
      return allContent;
    }
  }

  const popupContentETA = (idVehicle: number) => { //creation display of eta content
    let marker = markers?.get(idVehicle);
    let allContent;

    if(marker) {
      allContent = (
        <div className="flexHorizontal gap8 poppins">
          <svg width="15" height="23" viewBox="0 0 25 25">
            <path fill="#2196F3FF"
                  d="M12 0c-6.63 0-12 5.37-12 12 0 8.45 11.38 19.29 11.84 19.77 0.21 0.2 0.49 0.31 0.77 0.31s0.56-0.11 0.77-0.31c0.46-0.48 11.84-11.32 11.84-19.77 0-6.63-5.37-12-12-12zM12 18.75c-2.97 0-5.39-2.42-5.39-5.39s2.42-5.39 5.39-5.39 5.39 2.42 5.39 5.39-2.42 5.39-5.39 5.39z"></path>
          </svg>
          <div>
            <h2 className="fontSize12 secondaryColor">{marker.address}</h2>
          </div>
        </div>
      );
      return allContent;
    }
  }

  const popupContentETADestination = (idVehicle: number) => { //creation display of eta content for destination
    let marker = markers?.get(idVehicle);
    let allContent, setAddress;
    if (marker && marker.gendate && marker.endPosition) {
      return getAddress(marker.endPosition.lat, marker.endPosition.lng).then((address) => {
        setAddress = address;
        allContent = (
          <div className="flexHorizontal gap8 poppins">
            <svg width="15" height="23" viewBox="0 0 25 25">
              <path fill="#2196F3FF"
                    d="M12 0c-6.63 0-12 5.37-12 12 0 8.45 11.38 19.29 11.84 19.77 0.21 0.2 0.49 0.31 0.77 0.31s0.56-0.11 0.77-0.31c0.46-0.48 11.84-11.32 11.84-19.77 0-6.63-5.37-12-12-12zM12 18.75c-2.97 0-5.39-2.42-5.39-5.39s2.42-5.39 5.39-5.39 5.39 2.42 5.39 5.39-2.42 5.39-5.39 5.39z"></path>
            </svg>
            <div>
              <h2 className="fontSize12 secondaryColor">{setAddress}</h2>
            </div>
          </div>
        );

        return allContent;
      }).catch((error) => {
        console.error(error);
        return null;
      });
    }
  }

  const createMarker = (idVehicle: number) => {
    let bounds = new google.maps.LatLngBounds();

    if (markers) {
      const value = markers.get(idVehicle);

      const markerPosition = value?.position;
      if (markerPosition) {
        const newMarker = new google.maps.Marker({
          position: markerPosition,
          map: map!,
          icon: marketicon(value),
        });

        markerRefs.set(idVehicle, newMarker as google.maps.Marker);

        infoWindowRefs.set(idVehicle, new google.maps.InfoWindow());

        const content = popupContentPS(idVehicle);
        if (content) {
          infoWindowRefs.get(idVehicle).setContent(ReactDOMServer.renderToString(content));
        }
        newMarker.addListener("click", async () => {
          infoWindowRefs.get(idVehicle).open({
            anchor: newMarker,
            map,
          });
        });

        bounds.extend(markerPosition);
      }

      if (map) {  //center the map on the first marker created
         map?.fitBounds(bounds);
         map.setZoom(15)
      }

    }
  }
  const modifiedMarker = (point: WsData) => {
    let bounds = new google.maps.LatLngBounds(),
        positionNull = point.position.lat && point.position.lng ? false : true;

    if (markers && markerRefs) {
      const markerGoogle = markerRefs.get(point.id);
      if (markerGoogle) {
        if (!positionNull) {
          markerGoogle.setPosition(point.position);
        } // set position only if lat and lng are known beacuse of private status
        markerGoogle.setIcon(marketicon(point));

        setMarkerRefs((prevDataMap) => {
            markerRefs.set(point.id, markerGoogle as google.maps.Marker);
            return markerRefs;
          }
        );

        const content = popupContentPS(point.id);

        if (!positionNull) {
          if (content) {
            infoWindowRefs.get(point.id).setContent(ReactDOMServer.renderToString(content));
          }
        }
      }

    }
  }

  const createRoute = () => {
    let bounds = new google.maps.LatLngBounds();
    if (markers) {
      const point = markers.get(wsData.vehicleId);
      
      let positionNull = point && point.position.lng && point.position.lat ? false : true;

      if (!positionNull) {
        // Reset markers
        if (startMarker) {
          startMarker.setMap(null);
          setStartMarker(null);
        }
        if (endMarker) {
          endMarker.setMap(null);
          setEndMarker(null);
        }
  
        // Delete the existing direction rendering if it exists
        if (directionsRenderer.current) {
          directionsRenderer.current.setMap(null);
          directionsRenderer.current = null;
        }
  
        directionsService.current = new google.maps.DirectionsService();
        directionsRenderer.current = new google.maps.DirectionsRenderer({
          map: map!,
          suppressMarkers: true,
        });
  
        if (point && point.endPosition) {
          const newStartMarker = new google.maps.Marker({
            position: point.position,
            icon: marketicon(point.idle),
            map: map!,
          });
  
          const newEndMarker = new google.maps.Marker({
            position: point.endPosition,
            icon: {
              path: 'M 0,0 m -12,0 a 12,12 0 1,0 24,0 a 12,12 0 1,0 -24,0',
              fillColor: '#2196F3',
              fillOpacity: 1,
              strokeColor: '#FFFFFF',
              strokeWeight: 4,
              scale: 1,
            },
            map: map!,
          });
  
          setStartMarker(newStartMarker);
          setEndMarker(newEndMarker);
  
          const request: google.maps.DirectionsRequest = {
            origin: point.position,
            destination: point.endPosition,
            travelMode: google.maps.TravelMode.DRIVING,
          };
  
          directionsService.current.route(request, (response, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              if (directionsRenderer.current) {
                directionsRenderer.current.setDirections(response);
              }
            }
          });
  
          bounds.extend(point.position);
          if (true) {
            bounds.extend(point?.endPosition);
          }
          if (map) {
            map?.fitBounds(bounds); //center map with respect to the displayed point
          }
  
          infoWindowRefs.set(wsData.vehicleId, new google.maps.InfoWindow());
  
          newStartMarker.addListener("click", async () => {
            infoWindowRefs.get(wsData.vehicleId).open({
              anchor: newStartMarker,
              map,
            });
            const content = await popupContentETA(wsData.vehicleId);
            if (content) {
              infoWindowRefs.get(wsData.vehicleId).setContent(ReactDOMServer.renderToString(content));
            }
          });
  
  
          newEndMarker.addListener("click", async () => {
            infoWindowRefs.get(wsData.vehicleId).open({
              anchor: newEndMarker,
              map,
            });
            const content = await popupContentETADestination(wsData.vehicleId);
            if (content) {
              infoWindowRefs.get(wsData.vehicleId).setContent(ReactDOMServer.renderToString(content));
            }
          });
        }
      }
      

    }
  }

  /* Zoom in on a point by clicking on its registration in the map */
  const zoomToMarker = (markerSelect: string | undefined) => {
    if (markers) {
      const marker = Array.from(markers.values()).find(marker => marker.registration === markerSelect);

      if (marker && mapRef.current) {
        const zoomLevel = 15;
        const center = marker.position;

        map?.setCenter(center);
        map?.setZoom(zoomLevel);
      }
    }
  };


  useEffect(() => {
    if (mapRef.current) {
      if (type?.type === "ps") {

        if (markers !== undefined) {
          if (oldMarkers === undefined) { //initializing the first markers
            markers?.forEach((value, key) => {
              createMarker(key);
            })
            setOldMarkers(markers);

          } else {
            markers.forEach((value, key) => {
              if (oldMarkers.has(key)) { //check if the marker already exists
                if (markers) {
                  if (oldMarkers.get(key) !== value) { //check if values have changed
                    modifiedMarker(value);
                  }
                }
              } else {
                createMarker(key);
              }
            });
            setOldMarkers(markers);
          }
        }

      } else { //Display ETA
        createRoute();
      }


    }
  }, [markers]);

  useEffect(() => {
    if (mapRef.current && (mapContext?.registration !== "" || mapContext?.registration !== undefined)) {
      zoomToMarker(mapContext?.registration);
    }
  }, [mapContext?.registration]);

  return (
    <div className='width100 height100 zIndex1 relative'>
      <div id="map" ref={mapRef} className="width100 height100 relative"></div>
    </div>
  );
}

export default Map;