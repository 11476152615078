import {FC, useState} from 'react';
import './Tooltip.scss';

interface TooltipProps {
  text: string,
}

const Tooltip: FC<TooltipProps> = ({text}) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <div className="tooltip relative">
      <span className="material-symbols-outlined icon"
            onMouseEnter={() => {setVisible(true)}}
            onMouseLeave={() => {setVisible(false)}}
      >
        info
      </span>
      {visible ? <div className="text fontSize10 colorWhite poppinsMedium radius4 absolute">{text}</div> : <div/>}
    </div>
  );
}

export default Tooltip;