import './PanelStatus.scss';
import {FC} from "react";
import IconNormalVehicle from '../../res/icon-personalized/IconNormalVehicle';

interface StatusProps {
  iconBtn: string,
  handleClick: () => void,
  showHide: string,
  status: Array<object>
}

const PanelStatus: FC<StatusProps> = ({iconBtn, handleClick, showHide, status}) => {

  const statusList: any = status.map((status: any, index) => {
    return <div key={index} className='flexHorizontal mB16'>
      <IconNormalVehicle color={status.color}/><span className='mL16'>{status.name}</span></div>
  });

  return (
    <div className={`zIndex3 absolute top50Pct statusListCtn flexHorizontal trans02s ${showHide}`}>
      <button className='zIndex2 btn showStatusBtn backPrimary radius4Left colorWhite overflowHidden flexHorizontalCenter hover trans02s'
        onClick={() => handleClick()}>
        <span className="material-symbols-outlined fontSize45">
          {iconBtn}
        </span>
      </button>
      <div className='zIndex1 shadow statusList backWhite p16 borderBox flexVerticalCenterStart relative radius8Left fontSize16'>
        <span className='poppinsMedium height20 alignText width100'>Liste des statuts possibles</span>
        <div className='height80 width100 list overflowY scrollbarLight'>
          {statusList}
        </div>
      </div>
    </div>
  );
}

export default PanelStatus;